import * as ko from "knockout";
import * as amplify from "amplify";
import * as globalConfig from "globalConfig";

// Services
import { UniversalBasketService } from 'Content/ts/UniversalBasket/Services/universalBasketService';

// Components
import "Content/ts/UniversalBasket/universal-basket-timer";
import "Content/ts/Shared/Language/language-selector";

interface NavbarOptionsParams {
    UniversalBasketEnabled: boolean;
    ShowBasketOptions: boolean;
    LegacyResponsiveHeader: boolean;
}

export default class NavbarOptions {
    basketSecondsUntilExpiry: KnockoutObservable<number>;
    basketItemCount: KnockoutObservable<number>;
    isUpdating: KnockoutObservable<boolean>;

    /** Whether Universal Basket is enabled. If false, basket timer isn't used and basket count doesn't need item count passing to it. */
    universalBasketEnabled: boolean;

    /** Whether to show the basket timer and basket count. */
    showBasketOptions: boolean;

    /** Whether using a legacy navbar (determines the markup to use for the options). */
    legacyResponsiveHeader: boolean;

    constructor(params: NavbarOptionsParams) {
        this.universalBasketEnabled = params.UniversalBasketEnabled ?? true;
        this.showBasketOptions = params.ShowBasketOptions ?? true;
        this.legacyResponsiveHeader = params.LegacyResponsiveHeader ?? false;

        /** If not using Universal Basket, we don't need to get/update the item count or time remaining. */
        if (!this.universalBasketEnabled) {
            return;
        }

        this.basketItemCount = ko.observable(0);
        this.basketSecondsUntilExpiry = ko.observable(0);
        this.isUpdating = ko.observable(false);
        this.update();

        /** Listen for event that should update the count/timer. */
        amplify.subscribe(globalConfig.AmplifyEvents.Names.CountBasketItems, function () {
            this.update();
        }.bind(this));
    }

    public update(): void {
        this.isUpdating(true);
        UniversalBasketService.getBasketCountAndExpiryAsync().then((result) => {
            this.basketItemCount(result.ItemCount);
            this.basketSecondsUntilExpiry(result.SecondsUntilExpiry);
        }).finally(() => {
            this.isUpdating(false);
        });
    }
}

const componentName = "navbar-options";
ko.components.unregister(componentName);
ko.components.register(componentName, {
    viewModel: NavbarOptions,
    template: { require: `text!/Themes/Default/Content/ts/Shared/Navigation/${componentName}.html` }
});
