import * as ko from "knockout";
import * as _ from "underscore";

// Helpers
import "bootstrap";
import "fontAwesomeBindings";

// Models
import { NavigationMenuResponse } from "Content/ts/Models/Shared/Navigation/MainMenu/MainMenuDetails";
import { MappedNavigationDetails } from "Content/ts/Models/Shared/Navigation/MainMenu/MappedNavigationDetails";

// Services
import { MainNavigationMenuServices } from "Content/ts/Shared/Navigation/MainMenu/Services/MainNavigationMenuServices";
import { ConfigureNavigationMenuService } from "Content/ts/Shared/Navigation/MainMenu/Services/ConfigureNavigationMenuService";

export default class MainNavigationMenu {
    LoadingAppearanceDelay: number = 750; // Purpose    :   Time in milliseconds before the loading message appears.

    Timer: number;
    IsLargerResolution: boolean;

    HasErrorOccurred: KnockoutObservable<boolean>;
    DisplayTimerExpired: KnockoutObservable<boolean>;
    IsRetrievingNavigationMenu: KnockoutObservable<boolean>;

    NavigationOptions: KnockoutObservableArray<MappedNavigationDetails>;

    DisplayLoadingCondition: KnockoutComputed<boolean>;

    constructor() {
        // Standard Module Properties
        this.HasErrorOccurred = ko.observable(false);
        this.DisplayTimerExpired = ko.observable(false);
        this.IsRetrievingNavigationMenu = ko.observable(false);

        this.NavigationOptions = ko.observableArray([]);

        const currentWidth = $(window).width();
        this.IsLargerResolution = currentWidth > 1200;

        // Initialisation Methods
        this.ConfigureListeners();
        this.RetrieveNavigationMenu();
        this.SetNavigationMenuDisplay();

        this.ComputedMethods();
        this.SubscribeMethods();
    }

    private ConfigureLoadingTimer(): void {
        this.DisplayTimerExpired(false);
        if (this.Timer != null) {
            clearTimeout(this.Timer);
        }
        this.Timer = setTimeout(() => {
            this.Timer = null;
            this.DisplayTimerExpired(true);
        }, this.LoadingAppearanceDelay);
    };

    private ConfigureListeners(): void {
        $(window).resize(() => {
            this.SetNavigationMenuDisplay();
        });
    };

    private SetNavigationMenuDisplay(): void {
        const currentWidth = $(window).width();
        if (currentWidth > 1200) {
            $("#lgd-navigation-menu-options").addClass("in");
        } else {
            (<any>$("#lgd-navigation-menu-options")).collapse("hide");
        }
    };

    private RetrieveNavigationMenu(): void {
        this.IsRetrievingNavigationMenu(true);

        MainNavigationMenuServices.RetrieveNavigationMenuStructure()
            .then((response: Array<NavigationMenuResponse>) => {
                this.NavigationOptions(ConfigureNavigationMenuService.BuildNavigationMenu(response));
            })
            .catch(() => {
                this.HasErrorOccurred(true);
            })
            .finally(() => {
                this.IsRetrievingNavigationMenu(false);
                this.SetupChevronOnClick();
            });
    };

    private ComputedMethods() {
        this.DisplayLoadingCondition = ko.pureComputed(() => this.IsRetrievingNavigationMenu() && this.DisplayTimerExpired() && !this.HasErrorOccurred());
    }

    private SubscribeMethods(): void {
        this.IsRetrievingNavigationMenu.subscribe((value: boolean): void => {
            if (value) {
                this.ConfigureLoadingTimer();
            }
        });
    };

    private SetupChevronOnClick(): void {
        $('.lgd-navigation-chevron').on('click', function (e: JQuery.ClickEvent) {
            e.stopPropagation();
            $(this).parent().parent().trigger('click');
        })
    }
}

const moduleName = "main-navigation-menu";
ko.components.unregister(moduleName);
ko.components.register(moduleName, {
    viewModel: MainNavigationMenu,
    template: { require: `text!/Themes/Default/Content/ts/Shared/Navigation/MainMenu/${moduleName}.html` }
});